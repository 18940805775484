// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD2XQ0p8q9coLEYFjPVBfQHBlxFsiqhp3Q",
    authDomain: "misaprojekt-6cc6f.firebaseapp.com",
    databaseURL: "https://misaprojekt-6cc6f-default-rtdb.firebaseio.com",
    projectId: "misaprojekt-6cc6f",
    storageBucket: "misaprojekt-6cc6f.appspot.com",
    messagingSenderId: "133574758369",
    appId: "1:133574758369:web:38627fb013fe120d9bcca0",
    measurementId: "G-HMH2ZQFEZH"
  };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);