import React, { useState, useRef, useEffect } from 'react';
import { usePortfolioData } from './hooks/usePortfolioData';
import './styles/fonts.css';

// ScrollToTop Button Component
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 z-50 
            bg-white p-3 rounded-full shadow-lg 
            hover:shadow-xl transition-all duration-300
            focus:outline-none"
          aria-label="Scroll to top"
        >
          <svg
            className="w-6 h-6 transform rotate-180"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
          </svg>
        </button>
      )}
    </>
  );
};

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-white">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
  </div>
);

const Portfolio = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const contactRef = useRef(null);
  const aboutRef = useRef(null);
  const { images, pdfUrl, loading, error } = usePortfolioData();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contactRef.current && !contactRef.current.contains(event.target)) {
        setShowContact(false);
      }
      if (aboutRef.current &&
        !aboutRef.current.contains(event.target) &&
        !event.target.closest('[data-about-trigger]')) {
        setShowAbout(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const aboutText = `*7.7.2000 Ostrava, CZ
Academy of Arts, Architecture and Design in Prague: Studio of Fashion and Footwear design (Liběna Rochová)
Academy of Arts, Architecture and Design in Prague: Studio of Fine Arts III (Michal Pěchouček, Dominik Gajarský)
Goldsmiths, University of London: MFA Fine Arts`;

  if (loading) return <LoadingSpinner />;
  if (error) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white font-cooper">
      {/* Fixed Navigation */}
      <div className="fixed top-0 left-0 right-0 z-50">
        <nav className="bg-white">
          <div className="max-w-5xl mx-auto px-4 py-4">
            <div className="flex flex-col sm:flex-row items-center justify-center
      gap-6 sm:gap-16 md:gap-24 lg:gap-32 
      text-xl sm:text-lg">
              <button
                onClick={() => {
                  setShowAbout(!showAbout);
                  setShowContact(false);
                }}
                className={`hover:font-semibold transition-all italic md:text-2xl sm:text-lg duration-200 ${showAbout ? 'font-semibold' : 'font-normal'
                  }`}
              >
                ABOUT
              </button>
              <button
                onClick={() => window.open(pdfUrl, '_blank', 'noopener,noreferrer')}
                className="hover:font-semibold transition-all italic md:text-2xl sm:text-lg duration-200"
              >
                PDF PORTFOLIO
              </button>
              <button
                onClick={() => {
                  setShowContact(!showContact);
                  setShowAbout(false);
                }}
                className={`hover:font-semibold transition-all italic md:text-2xl sm:text-lg duration-200 ${showContact ? 'font-semibold' : 'font-normal'
                  }`}
              >
                CONTACT
              </button>
            </div>
          </div>
        </nav>

        {/* Expanding Content Sections */}
        {(showAbout || showContact) && (
          <div className="bg-white border-t border-gray-100">
            <div className="max-w-5xl mx-auto px-4 py-6">
              {showAbout && (
                <div className="whitespace-pre-line text-lg sm:text-xl flex justify-center">
                  <div className="max-w-2xl">
                    {aboutText}
                  </div>
                </div>
              )}
              {showContact && (
                <div className="max-w-5xl mx-auto px-4">
                  <div className="flex justify-between items-start">
                    <div className="flex-1"></div>
                    <div className="flex-1"></div>
                    <div className="flex-1"></div>
                    <div className="flex flex-col items-end flex-1 space-y-3">
                      <a
                        href="mailto:asim.sindlerova@gmail.com"
                        className="text-lg sm:text-xl hover:font-semibold hover:underline transition-all"
                      >
                        Email
                      </a>
                      <a
                        href="https://www.instagram.com/sindlerova.michaela/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-lg sm:text-xl hover:font-semibold hover:underline transition-all"
                      >
                        Instagram
                      </a>
                    </div>
                    <div className='flex-1'></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
        }
      </div >

      <ScrollToTopButton />

      {/* Artist Name */}
      <div className="fixed left-0 right-0" style={{ zIndex: 1, top: '35vh' }}>
        <h1 className="text-center font-semibold italic
          text-3xl 
          sm:text-4xl 
          md:text-6xl 
          lg:text-7xl 
          xl:text-8xl">
          MICHAELA ŠINDLEROVÁ
        </h1>
      </div>

      {/* Scrollable Images Container */}
      <div className="relative" style={{ zIndex: 10 }}>
        <div className="pt-[60vh] sm:pt-[70vh] space-y-24 sm:space-y-48">
          {images.map((image, index) => (
            <div
              key={index}
              className="flex items-center justify-center"
            >
              <div className="w-[85vw] sm:w-[75vw] md:w-[65vw] max-w-3xl px-2 sm:px-4">
                <img
                  src={image}
                  alt={`Artwork ${index + 1}`}
                  className="w-full object-contain bg-white"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

export default Portfolio;