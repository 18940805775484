// src/hooks/usePortfolioData.js
import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

export const usePortfolioData = () => {
  const [data, setData] = useState({
    images: [],
    pdfUrl: '',
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch images
        const imagesQuery = await getDocs(collection(db, 'photos'));
        const images = imagesQuery.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .sort((a, b) => a.order - b.order) // If you have an order field
          .map(img => img.url);

        // Fetch PDF URL
        const pdfDoc = await getDocs(collection(db, 'portfolio'));
        const pdfItem = pdfDoc.docs.find(x => x.data);
        const pdfUrl = pdfItem.exists() ? pdfItem.data().url : '';

        setData({
          images,
          pdfUrl,
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
        setData(prev => ({
          ...prev,
          loading: false,
          error: 'Failed to load portfolio data'
        }));
      }
    };

    fetchData();
  }, []);

  return data;
};